<template>
  <div class="featured-chips">
    <template v-if="!readMore[index] && !showFullEntities">
      <span class="tagged-title">Tagged entities :</span>
      <span :key="index" v-for="(data, index) of featuredEntities.slice(0, 5)">
        <b-tag
          :class="getEntitiesClass(data.Type)+'-tag'"
          rounded
          class="tag-entities"
          close-type="is-white"
          close-icon-type="is-success"
          aria-close-label="Close tag">
          <span>
            <img :src="getSourceImg(data.Type)" />
          </span>
          <span class="text">{{ data.Text }}</span>
        </b-tag>
      </span>
    </template>
    <template v-if="readMore[index] || showFullEntities">
      <span>Tagged entities :</span>
      <span :key="index" v-for="(data, index) of featuredEntities">
        <b-tag
          :class="getEntitiesClass(data.Type)+'-tag'"
          class="tag-entities"
          rounded
          close-type="is-white"
          close-icon-type="is-success"
          aria-close-label="Close tag">
          <span>
            <img :src="getSourceImg(data.Type)" />
          </span>
          <span class="text">{{ data.Text }}</span>
        </b-tag>
      </span>
    </template>
    <template v-if="!showFullEntities">
      <div
        class="read-button"
        @click="showMore(index)"
        v-if="!readMore[index] && this.featuredEntities.length > 5">
        view more
      </div>
      <div
        class="read-button"
        @click="showLess(index)"
        v-if="readMore[index] && this.featuredEntities.length > 5">
        view less
      </div>
    </template>
  </div>
</template>

<script>
export default {
  name: 'NewsTag',
  props: {
    featuredEntities: {
      type: Array
    },
    index: {
      type: Number
    },
    user_preference_mode: {
      type: String
    },
    showFullEntities: {
      type: Boolean
    }
  },
  data () {
    return {
      readMore: {},
      sourceImg: [
        {
          type: 'ORGANIZATION',
          light: require('@/assets/esg-entities/building.svg'),
          dark: require('@/assets/esg-entities/building_dark.svg')
        },
        {
          type: 'QUANTITY',
          light: require('@/assets/esg-entities/group.svg'),
          dark: require('@/assets/esg-entities/group_dark.svg')
        },
        {
          type: 'LOCATION',
          light: require('@/assets/esg-entities/location.svg'),
          dark: require('@/assets/esg-entities/location_dark.svg')
        },
        {
          type: 'DATE',
          light: require('@/assets/esg-entities/calendar.svg'),
          dark: require('@/assets/esg-entities/calendar_dark.svg')
        },
        {
          type: 'PERSON',
          light: require('@/assets/esg-entities/user.svg'),
          dark: require('@/assets/esg-entities/user_dark.svg')
        },
        {
          type: 'EVENT',
          light: require('@/assets/esg-entities/event.svg'),
          dark: require('@/assets/esg-entities/event_dark.svg')
        },
        {
          type: 'COMMERCIAL_ITEM',
          light: require('@/assets/esg-entities/commercial.svg'),
          dark: require('@/assets/esg-entities/commercial_dark.svg')
        },
        {
          type: 'TITLE',
          light: require('@/assets/esg-entities/title.svg'),
          dark: require('@/assets/esg-entities/title_dark.svg')
        }
      ],
      defaultSourceImg: {
        light: require('@/assets/esg-entities/application.svg'),
        dark: require('@/assets/esg-entities/application_dark.svg')
      }
    }
  },
  computed: {},
  methods: {
    showMore (id) {
      this.$set(this.readMore, id, true)
    },
    showLess (id) {
      this.$set(this.readMore, id, false)
    },
    getEntitiesClass (name) {
      if (
        name !== 'LOCATION' &&
        name !== 'PERSON' &&
        name !== 'DATE' &&
        name !== 'ORGANIZATION' &&
        name !== 'QUANTITY' &&
        name !== 'EVENT' &&
        name !== 'COMMERCIAL_ITEM' &&
        name !== 'TITLE'
      ) {
        return 'application'
      } else {
        return name.toLowerCase()
      }
    },
    getSourceImg (name) {
      let imgSrc = ''
      if (
        name !== 'LOCATION' &&
        name !== 'PERSON' &&
        name !== 'DATE' &&
        name !== 'ORGANIZATION' &&
        name !== 'QUANTITY' &&
        name !== 'EVENT' &&
        name !== 'COMMERCIAL_ITEM' &&
        name !== 'TITLE'
      ) {
        if (this.user_preference_mode === 'light') {
          imgSrc = this.defaultSourceImg.light
        } else {
          imgSrc = this.defaultSourceImg.dark
        }
      } else {
        this.sourceImg.forEach((ele) => {
          if (ele.type === name) {
            if (this.user_preference_mode === 'light') {
              imgSrc = ele.light
            } else {
              imgSrc = ele.dark
            }
          }
        })
      }
      return imgSrc
    }
  }
}
</script>

<style lang="scss" scoped>
.featured-chips {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;

  span {
    margin-right: 0.2rem;
    margin-top: 0.2rem;
  }
  .tag-entities {
    font-weight: 400px;
    font-size: 13px;
  }
  .location-tag {
    background: var(--location-entities);
    border: 1px solid var(--location-entities-border);

    span {
      color: var(--location-entities-text);
    }
  }
  .person-tag {
    background: var(--person-entities);
    border: 1px solid var(--person-entities-border);

    span {
      color: var(--person-entities-text);
    }
  }
  .date-tag {
    background: var(--date-entities);
    border: 1px solid var(--date-entities-border);

    span {
      color: var(--date-entities-text);
    }
  }
  .quantity-tag {
    background: var(--quantity-entities);
    border: 1px solid var(--quantity-entities-border);

    span {
      color: var(--quantity-entities-text);
    }
  }

  .application-tag {
    background: var(--application-entities);
    border: 1px solid var(--application-entities-border);

    span {
      color: var(--application-entities-text);
    }
  }

  .organization-tag {
    background: var(--organisation-entities);
    border: 1px solid var(--organisation-entities-border);

    span {
      color: var(--organisation-entities-text);
    }
  }

  .event-tag {
    background: var(--event-entities);
    border: 1px solid var(--event-entities-border);

    span {
      color: var(--event-entities-text);
    }
  }
  .title-tag {
    background: var(--title-entities);
    border: 1px solid var(--title-entities-border);

    span {
      color: var(--title-entities-text);
    }
  }

  .commercial_item-tag {
    background: var(--commercial-entities);
    border: 1px solid var(--commercial-entities-border);

    span {
      color: var(--commercial-entities-text);
    }
  }

  .read-button {
    color: #f57777;
    cursor: pointer;
    margin-left: 0.2rem;
    margin-top: 0.3rem;
  }
}
</style>
